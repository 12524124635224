import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import { Col, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../UI/Link"
import { boxClass, detailsWrapperClass } from "./DetailSummaryItems.module.scss"
import SectionHeading from '../UI/SectionHeading'
export const fragment = graphql`
  fragment detailSummaryItemsFragment on WpPage_Flexlayouts_FlexibleLayouts_DetailSummaryItems {
    sectionHeading
    headingType
    detailItems {
      itemHeading
      text
      icon {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 63
              placeholder: NONE
            )
          }
        }
      }
      link {
        title
        url
      }
    }
  }
`

const DetailSummaryItems = ({ sectionHeading, detailItems, headingType }) => {
  return (
    <section className={`bg-pale-grey`}>
      <Container>
      <SectionHeading 
        text={sectionHeading} 
        type={headingType} 
        className="text-start mb-5"
        /> 
        <Row className="d-flex justify-content-between m-0">
          {detailItems.map((item, i) => {
            const image = getImage(item.icon?.localFile)
            return (
              <Col
                className={
                  i + 1 === detailItems.length
                    ? `pl-0 pb-0`
                    : `pl-0 pb-6 pb-lg-0`
                }
                key={`detailItem${i}`}
                xs={12}
                lg={4}>
                <div className={`${boxClass}`}>
                  <div className={`${detailsWrapperClass} mb-4`}>
                    <GatsbyImage image={image} alt={item.icon?.altText} />
                  </div>
                  <h3 dangerouslySetInnerHTML={{ __html: item.itemHeading }} />
                  <p
                    dangerouslySetInnerHTML={{ __html: item.text }}
                    className="py-2"
                  />
                  <div>
                    <Link
                      classes="linkClass"
                      link={item.link.url}
                      title={item.link.title}
                    />
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default DetailSummaryItems
